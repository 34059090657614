import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Login from './Login';
import Home from './Home';
import Results from './Results';
import Users from './Users';

import * as serviceWorker from './serviceWorker';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './vendors/bootstrap/dist/css/bootstrap.min.css';
import './vendors/font-awesome/css/font-awesome.min.css';
import './vendors/nprogress/nprogress.css';
import './vendors/animate.css/animate.min.css'
import './custom.min.css';

ReactDOM.render(
  <div id="mainContainer" className="nav-sm">
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/results">
          <Results />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Router>
  </div>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
