import React, { useEffect } from 'react';

import {
  useHistory,
  Link
} from "react-router-dom";

import './Menu.css';

function Menu({currentPage}) {
  const history = useHistory();
  const userRole = localStorage.getItem('ur');

  if(userRole === null) {
    localStorage.removeItem('ut');
    history.push('/login');
  }

  const menuSwitch = function() {
    const mainContainer = document.getElementById('mainContainer');
    if(mainContainer.className === 'nav-md') {
      mainContainer.className = 'nav-sm';
    } else {
      mainContainer.className = 'nav-md';
    }
  }

  useEffect(() => {
    const mainContainer = document.getElementById('mainContainer');
    if(window.innerWidth < 600) {
      mainContainer.className = 'nav-md';
    }
  }, []);

  return (
    <>
      <div className="col-md-3 left_col">
        <div className="left_col scroll-view">
          <div className="navbar nav_title" style={{border: 0}}>
            <img src="logo.png" width="100%" alt="logo"/>
          </div>

          <div className="clearfix"></div>

          <br />

          <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
            <div className="menu_section">
              <ul className="nav side-menu">
                <li className={currentPage === 'Home'?'active':''}>
                  <Link className="menu-item" to="/home"><i className="fa fa-search"></i> Search</Link>
                </li>
                <li className={currentPage === 'Results'?'active':''}>
                  <Link className="menu-item" to="/results"><i className="fa fa-table"></i> Penalties</Link>
                </li>
                {userRole==='admin'?(
                <li className={currentPage === 'Users'?'active':''}>
                  <Link className="menu-item" to="/users"><i className="fa fa-users"></i> Users</Link>
                </li>) : null}
                <li>
                  <a href="/login" onClick={() => {
                    localStorage.removeItem('ut');
                    localStorage.removeItem('uk');
                    history.push('/login')
                  }}><i className="glyphicon glyphicon-off"></i> Log out</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="top_nav">
        <div className="nav_menu">
          <div className="nav toggle">
            <a id="menu_toggle" onClick={() => { menuSwitch(); }}><i className="fa fa-bars"></i></a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
