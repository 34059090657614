import React, { useState, useEffect } from 'react';
import Menu from './Menu'
import { Formik, ErrorMessage } from 'formik';

import {
  useLocation,
  useHistory
} from "react-router-dom";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './Results.css';

import config from './config'

import SecureData from './secure'

function Results() {
  const history = useHistory();
  const location = useLocation();
  const filters = location.state;

  const unlockKey = localStorage.getItem('uk');
  const userToken = localStorage.getItem('ut');
  const userRole = localStorage.getItem('ur');
  const data = JSON.parse(SecureData.decrypt(localStorage.getItem('data'), unlockKey));

  const [showPenalty, setShowPenalty] = useState('');
  const [addPenalty, setAddPenalty] = useState(null);
  const [editPenalty, setEditPenalty] = useState(null);
  const [showPenaltyAttachments, setShowPenaltyAttachments] = useState(null);
  const [showNFAAttachments, setShowNFAAttachments] = useState(null);
  const [showNFA, setShowNFA] = useState('');
  const [addNFA, setAddNFA] = useState(null);
  const [editNFA, setEditNFA] = useState(null);
  const [editArticle, setEditArticle] = useState(null);
  const [showAttachments, setShowAttachments] = useState('');
  const [addAttachments, setAddAttachments] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [lastUpdate, setLastUpdate] = useState("");

  const [results, setResults] = useState(data);
  
  function diff_minutes(dt2, dt1) {
   var diff =(dt2.getTime() - dt1.getTime()) / 1000;
   diff /= 60;
   return Math.abs(Math.round(diff));
  }
 
  const getData = function() {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }

    fetch(config.API_PATH + '/articles', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        localStorage.setItem('data', SecureData.encrypt(JSON.stringify(data.data), unlockKey));
        localStorage.setItem('last_update', new Date());
        document.location.reload();
      } else {
        localStorage.removeItem('ut');
        history.push('/login')
      }
    })
  }

  const insertArticle = function(values) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: values.name
      })
    }

    fetch(config.API_PATH + '/articles', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const updateArticle = function(row, values) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        article: values.article
      })
    }

    fetch(config.API_PATH + '/articles/' + row._id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const insertArticleRow = function(articleId, values) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        article: values.article,
        infringement: values.infringement,
        year: values.year
      })
    }

    fetch(config.API_PATH + '/articles/' + articleId, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const insertPenalty = function(penalty, values) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        penalty: values.penalty,
        gp: values.gp,
        driver: values.driver,
        session: values.session
      })
    }

    fetch(config.API_PATH + '/articles/penalties/' + penalty._id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const updatePenalty = function(row, values) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: values._id,
        penalty: values.penalty,
        gp: values.gp,
        driver: values.driver,
        session: values.session
      })
    }

    fetch(config.API_PATH + '/articles/penalties/' + row._id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const insertNFA = function(nfa, values) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        penalty: values.penalty,
        gp: values.gp,
        driver: values.driver,
        session: values.session
      })
    }

    fetch(config.API_PATH + '/articles/nfa/' + nfa._id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const updateNFA = function(row, values) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: values._id,
        penalty: values.penalty,
        gp: values.gp,
        driver: values.driver,
        session: values.session
      })
    }

    fetch(config.API_PATH + '/articles/nfa/' + row._id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const deleteArticle = function(articleId) {
    confirmAlert({
      title: 'Delete article',
      message: 'Are you sure you want to delete this article?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
        
            fetch(config.API_PATH + '/articles/' + articleId, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                getData();
              } else {
                localStorage.removeItem('ut');
                history.push('/login');
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const deleteRow = function(articleId, rowId) {
    confirmAlert({
      title: 'Delete article row',
      message: 'Are you sure you want to delete this article row?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
        
            fetch(config.API_PATH + '/articles/' + articleId + '/' + rowId, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                getData();
              } else {
                localStorage.removeItem('ut');
                history.push('/login');
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const deletePenalty = function(penalty) {
    confirmAlert({
      title: 'Delete penalty',
      message: 'Are you sure you want to delete this penalty?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
        
            fetch(config.API_PATH + '/articles/penalties/' + penalty._id, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                getData();
              } else {
                localStorage.removeItem('ut');
                history.push('/login');
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const deleteNFA = function(nfa) {
    confirmAlert({
      title: 'Delete NFA',
      message: 'Are you sure you want to delete this NFA?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
        
            fetch(config.API_PATH + '/articles/nfa/' + nfa._id, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                getData();
              } else {
                localStorage.removeItem('ut');
                history.push('/login');
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const insertAttachment = function(attachment, values) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: values.title,
        attachment: values.attachment
      })
    }

    fetch(config.API_PATH + '/articles/attachment/' + attachment._id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const deleteAttachment = function(attachment) {
    confirmAlert({
      title: 'Delete Attachment',
      message: 'Are you sure you want to delete this attachment?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
        
            fetch(config.API_PATH + '/articles/attachment/' + attachment._id, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                getData();
              } else {
                localStorage.removeItem('ut');
                history.push('/login');
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const insertPenaltyAttachment = function(penalty, values) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: values.title,
        attachment: values.url
      })
    }

    fetch(config.API_PATH + '/penalty/attachment/' + penalty._id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const deletePenaltyAttachment = function(attachment) {
    confirmAlert({
      title: 'Delete Attachment',
      message: 'Are you sure you want to delete this attachment?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
        
            fetch(config.API_PATH + '/penalty/attachment/' + attachment._id, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                getData();
              } else {
                localStorage.removeItem('ut');
                history.push('/login');
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const insertNFAAttachment = function(penalty, values) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: values.title,
        attachment: values.url
      })
    }

    fetch(config.API_PATH + '/nfa/attachment/' + penalty._id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        alert(data.message);
        getData();
      } else {
        alert(data.message);
      }
    })
  }

  const deleteNFAAttachment = function(attachment) {
    confirmAlert({
      title: 'Delete Attachment',
      message: 'Are you sure you want to delete this attachment?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
        
            fetch(config.API_PATH + '/nfa/attachment/' + attachment._id, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                getData();
              } else {
                localStorage.removeItem('ut');
                history.push('/login');
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const editRowDo = function(row, values) {
    confirmAlert({
      title: 'Update article',
      message: 'Are you sure you want to update this article?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                article: values.article,
                infringement: values.infringement,
                year: values.year
              })
            }
        
            fetch(config.API_PATH + '/articles/edit/' + row._id, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                alert(data.message);
                getData();
              } else {
                alert(data.message);
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  useEffect(() => {
    //auto update if data are older than 1 hour
    let lastUpdate = localStorage.getItem('last_update');
    if(lastUpdate !== null) {
      let tmpdate = new Date(lastUpdate);
      setLastUpdate(tmpdate.getDate() + '/' + (tmpdate.getMonth() + 1) + '/' + tmpdate.getFullYear() + ' ' + tmpdate.getHours() + ':' + tmpdate.getMinutes());
    }
  
    if(lastUpdate === null || diff_minutes(new Date(lastUpdate), new Date()) > 30) {
      getData()
    }

    if(filters) {
      let tmpResults = results;

      if(filters.article) {
        tmpResults = results.filter(item => {
          return item.article === filters.article
        })
      }

      if(filters.decision) {
        //filter the results in general
        tmpResults = tmpResults.filter(item => {
          for(let row of item.data) {
            for(let penalty of row.penalties) {
              if(penalty.penalty.toLowerCase().indexOf(filters.decision.toLowerCase()) > -1) {
                return true;
              }
            }
            for(let nfa of row.nfa) {
              if(nfa.penalty.toLowerCase().indexOf(filters.decision.toLowerCase()) > -1) {
                return true;
              }
            }
          }
          return false;
        })

        //filter inner rows
        tmpResults.forEach(result => {
          result.data = result.data.filter(row => {
            for(let penalty of row.penalties) {
              if(penalty.penalty.toLowerCase().indexOf(filters.decision.toLowerCase()) > -1) {
                return true;
              }
            }
            for(let nfa of row.nfa) {
              if(nfa.penalty.toLowerCase().indexOf(filters.decision.toLowerCase()) > -1) {
                return true;
              }
            }
            return false;
          })
        });
      }

      if(filters.driver) {
        //filter the results in general
        tmpResults = tmpResults.filter(item => {
          for(let row of item.data) {
            for(let penalty of row.penalties) {
              if(penalty.driver.toLowerCase().indexOf(filters.driver.toLowerCase()) > -1) {
                return true;
              }
            }
          }
          return false;
        })

        //filter inner rows
        tmpResults.forEach(result => {
          result.data = result.data.filter(row => {
            for(let penalty of row.penalties) {
              if(penalty.driver.toLowerCase().indexOf(filters.driver.toLowerCase()) > -1) {
                return true;
              }
            }
            return false;
          })
        });

        //filter penalties and nfa list
        tmpResults.forEach(result => {
          result.data.forEach(row => {
            row.penalties = row.penalties.filter(penalty => {
              if(penalty.driver.toLowerCase().indexOf(filters.driver.toLowerCase()) > -1) {
                return true;
              }
            })
            return false;
          })
        });
      }

      if(filters.race) {
        //filter the results in general
        tmpResults = tmpResults.filter(item => {
          for(let row of item.data) {
            for(let penalty of row.penalties) {
              if(penalty.gp.toLowerCase().indexOf(filters.race.toLowerCase()) > -1) {
                return true;
              }
            }
          }
          return false;
        })

        //filter inner rows
        tmpResults.forEach(result => {
          result.data = result.data.filter(row => {
            for(let penalty of row.penalties) {
              if(penalty.gp.toLowerCase().indexOf(filters.race.toLowerCase()) > -1) {
                return true;
              }
            }
            return false;
          })
        });

        //filter penalties and nfa list
        tmpResults.forEach(result => {
          result.data.forEach(row => {
            row.penalties = row.penalties.filter(penalty => {
              if(penalty.gp.toLowerCase().indexOf(filters.race.toLowerCase()) > -1) {
                return true;
              }
            })
            return false;
          })
        });
      }

      if(filters.session) {
        //filter the results in general
        tmpResults = tmpResults.filter(item => {
          for(let row of item.data) {
            for(let penalty of row.penalties) {
              if(penalty.session.toLowerCase().indexOf(filters.session.toLowerCase()) > -1) {
                return true;
              }
            }
          }
          return false;
        })

        //filter inner rows
        tmpResults.forEach(result => {
          result.data = result.data.filter(row => {
            for(let penalty of row.penalties) {
              if(penalty.session.toLowerCase().indexOf(filters.session.toLowerCase()) > -1) {
                return true;
              }
            }
            return false;
          })
        });

        //filter penalties and nfa list
        tmpResults.forEach(result => {
          result.data.forEach(row => {
            row.penalties = row.penalties.filter(penalty => {
              if(penalty.session.toLowerCase().indexOf(filters.session.toLowerCase()) > -1) {
                return true;
              }
            })
            return false;
          })
        });
      }

      if(filters.keyword) {
        //filter the results in general
        tmpResults = tmpResults.filter(item => {
          for(let row of item.data) {
              if(row.infringement.toLowerCase().indexOf(filters.keyword.toLowerCase()) > -1) {
                return true;
              }
          }
          return false;
        })

        //filter inner rows
        tmpResults.forEach(result => {
          result.data = result.data.filter(row => {
            if(row.infringement.toLowerCase().indexOf(filters.keyword.toLowerCase()) > -1) {
              return true;
            }
            return false;
          })
        });
      }

      //sort inner rows per year
      tmpResults.forEach(result => {
        result.data = result.data.sort((a, b) => {
          return b.year - a.year;
        })
      });

      //sort per article number
      tmpResults = [...tmpResults.sort(function(a, b) {
        const aNumArrMatch = a.article.match(/\d+/g);
        const bNumArrMatch = b.article.match(/\d+/g);

        const aNumArr = aNumArrMatch === null ? [] : aNumArrMatch.map(Number);
        const bNumArr = bNumArrMatch === null ? [] : bNumArrMatch.map(Number);

        let aNum = 0;
        aNumArr.forEach((num, indx) => {
          aNum += num * Math.pow(0.1, indx);
        })
        let bNum = 0;
        bNumArr.forEach((num, indx) => {
          bNum += num * Math.pow(0.1, indx);
        })
        return aNum - bNum;
      })]

      setResults(tmpResults);
    } else {
      let tmpResults = results;

      //sort inner rows per year
      tmpResults.forEach(result => {
        result.data = result.data.sort((a, b) => {
          return b.year - a.year;
        })
      });

      //sort per article number
      tmpResults = [...tmpResults.sort(function(a, b) {
        const aNumArrMatch = a.article.match(/\d+/g);
        const bNumArrMatch = b.article.match(/\d+/g);

        const aNumArr = aNumArrMatch === null ? [] : aNumArrMatch.map(Number);
        const bNumArr = bNumArrMatch === null ? [] : bNumArrMatch.map(Number);

        let aNum = 0;
        aNumArr.forEach((num, indx) => {
          aNum += num * Math.pow(0.1, indx);
        })
        let bNum = 0;
        bNumArr.forEach((num, indx) => {
          bNum += num * Math.pow(0.1, indx);
        })
        return aNum - bNum;
      })]

      setResults(tmpResults);
    }
  }, [])
  
  return (
    <div className="container body">
      <div className="main_container">
        <Menu currentPage="Results"></Menu>

        <div className="right_col" role="main">
          <div className="">
            <div className="page-title">
              <div className="title_left">
                <h3>Penalities Search</h3>
                <p>Last sync: {lastUpdate}</p>
              </div>
            </div>
            {userRole === 'admin' ? (
            <div className="col-md-6 col-sm-12 pb-3">
              <Formik
                initialValues={{
                  name: ''
                }}
                validate={values => {
                  const errors = {};
                  if (!values.name) {
                    errors.username = 'Required';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  insertArticle(values)
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                <form onSubmit={handleSubmit}>
                  <h2> Add a new article </h2>
                  <div className="item form-group">
                    <input type="text" className="form-control" placeholder="Article name" required="" name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <ErrorMessage name="name" component="div" />
                  </div>
                  <div>
                    <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                      <i className="fa fa-plus"></i> Add
                    </button>
                  </div>
                </form>
                )}
              </Formik>
            </div>) : null }
            <div className="clearfix"></div>
            {results.map((sheet, i) => {
              return (
              <div className="row" key={'sheet-'+i}>
                <div className="col-md-12 col-sm-12  ">
                  <div className="x_panel">
                    <div className="x_title">
                      {editArticle === sheet._id?(
                        <Formik
                        initialValues={sheet}
                        validate={values => {
                          const errors = {};
                          if (!values.article) {
                            errors.article = 'Required';
                          }
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          updateArticle(sheet, values)
                          setSubmitting(false);
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form className="row" onSubmit={handleSubmit}>
                            <div className="col-11 item form-group">
                              <input type="text" className="form-control" placeholder="Article" required="" name="article"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.article}
                              />
                              <ErrorMessage name="penalty" component="div" />
                            </div>
                            <div className="col-1">
                              <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                <i className="fa fa-check"></i>
                              </button>
                              <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => {setEditArticle(null);}}>
                                <i className="fa fa-close"></i>
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                      ):(
                        <h2>{sheet.article}</h2>
                      )}
                      {userRole === 'admin' ? (
                        editArticle === sheet._id?null:(
                          <>
                            <button className="btn btn-success pull-right" type="button" onClick={() => {setEditArticle(sheet._id);}}><i className="fa fa-pencil"></i></button>
                            <button className="btn btn-success pull-right" type="button" onClick={() => {deleteArticle(sheet._id);}}><i className="fa fa-trash"></i></button>
                          </>
                        )
                      ): null}
                      <div className="clearfix"></div>
                    </div>

                    <div className="x_content">

                      <div className="table-responsive">
                        <table className="table table-striped jambo_table bulk_action">
                          {userRole === 'admin' ? (
                            <colgroup>
                              <col span="1" style={{width: '15%'}} />
                              <col span="1" style={{width: '40%'}} />
                              <col span="1" style={{width: '5%'}} />
                              <col span="1" style={{width: '7%'}} />
                              <col span="1" style={{width: '7%'}} />
                              <col span="1" style={{width: '7%'}} />
                              <col span="1" style={{width: '19%'}} />
                            </colgroup>
                          ): (
                            <colgroup>
                              <col span="1" style={{width: '15%'}} />
                              <col span="1" style={{width: '40%'}} />
                              <col span="1" style={{width: '6%'}} />
                              <col span="1" style={{width: '13%'}} />
                              <col span="1" style={{width: '13%'}} />
                              <col span="1" style={{width: '13%'}} />
                            </colgroup>
                          )}
                          <thead>
                            <tr className="headings">
                              <th className="column-title">Article </th>
                              <th className="column-title">Infringement </th>
                              <th className="column-title">Year </th>
                              <th className="column-title">Penalties </th>
                              <th className="column-title">NFA </th>
                              <th className="column-title">Attachments </th>
                              {userRole === 'admin' ? (
                                <th className="column-title">Actions</th>
                              ): null}
                            </tr>
                          </thead>

                          <tbody>
                          {sheet.data.map((row, r) => {
                            return (
                              <tr key={'sheet-' + i + '-row-' + r}>
                                <td>{row.article}</td>
                                <td>{row.infringement}</td>
                                <td>{row.year}</td>
                                <td>
                                {row.penalties.length > 0?(
                                  <>
                                  <button className="btn btn-success" type="button" onClick={() => {setShowPenalty('sheet-' + i + '-row-' + r);}}>View ({row.penalties.length})</button>
                                  {(showPenalty === 'sheet-' + i + '-row-' + r)?(
                                    <div className="modal fade show" role="dialog" aria-modal="true" style={{display: 'block', paddingLeft: '0px'}}>
                                      <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h4 className="modal-title" id="myModalLabel">{row.article}</h4>
                                            <button type="button" className="close" data-dismiss="modal" onClick={() => {setShowPenalty('');}}><span aria-hidden="true">×</span></button>
                                          </div>
                                          <div className="modal-body">
                                            <p><b>Infringement</b>: {row.infringement}</p>
                                            <p><b>Year</b>: {row.year}</p>
                                            <table className="table table-striped jambo_table bulk_action">
                                              <thead>
                                                <tr className="headings">
                                                  <th className="column-title">Penalty </th>
                                                  <th className="column-title">GP </th>
                                                  <th className="column-title">Driver </th>
                                                  <th className="column-title">Session </th>
                                                  <th className="column-title">Action </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                              {row.penalties.map((penalty, p) => {
                                                return ((editPenalty === p && userRole === 'admin')?(
                                                  <Formik
                                                    initialValues={penalty}
                                                    validate={values => {
                                                      const errors = {};
                                                      if (!values.penalty) {
                                                        errors.penalty = 'Required';
                                                      }
                                                      if (!values.driver) {
                                                        errors.driver = 'Required';
                                                      }
                                                      if (!values.gp) {
                                                        errors.gp = 'Required';
                                                      }
                                                      if (!values.session) {
                                                        errors.session = 'Required';
                                                      }
                                                      return errors;
                                                    }}
                                                    onSubmit={(values, { setSubmitting }) => {
                                                      updatePenalty(row, values)
                                                      setSubmitting(false);
                                                    }}
                                                  >
                                                    {({
                                                      values,
                                                      handleChange,
                                                      handleBlur,
                                                      handleSubmit,
                                                      isSubmitting
                                                    }) => (
                                                    <tr key={'sheet-' + i + '-row-' + r + '-penalty-' + p + '-new'}>
                                                      <td colSpan="5">
                                                        <form className="row" onSubmit={handleSubmit}>
                                                          <div className="col-4 item form-group">
                                                            <input type="text" className="form-control" placeholder="Penalty" required="" name="penalty"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.penalty}
                                                            />
                                                            <ErrorMessage name="penalty" component="div" />
                                                          </div>
                                                          <div className="col-2 item form-group">
                                                            <input type="text" className="form-control" placeholder="GP" required="" name="gp"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.gp}
                                                            />
                                                            <ErrorMessage name="gp" component="div" />
                                                          </div>
                                                          <div className="col-2 item form-group">
                                                            <input type="text" className="form-control" placeholder="Driver" required="" name="driver"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.driver}
                                                            />
                                                            <ErrorMessage name="driver" component="div" />
                                                          </div>
                                                          <div className="col-2 item form-group">
                                                            <input type="text" className="form-control" placeholder="Session" required="" name="session"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.session}
                                                            />
                                                            <ErrorMessage name="session" component="div" />
                                                          </div>
                                                          <div className="col-2">
                                                          <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                                              <i className="fa fa-check"></i>
                                                            </button>
                                                            <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => {setEditPenalty(null);}}>
                                                              <i className="fa fa-close"></i>
                                                            </button>
                                                          </div>
                                                        </form>
                                                      </td>
                                                    </tr>
                                                    )}
                                                  </Formik>
                                                ):(
                                                  <>
                                                    <tr key={'sheet-' + i + '-row-' + r + '-penalty-' + p}>
                                                      <td>{penalty.penalty}</td>
                                                      <td>{penalty.gp}</td>
                                                      <td>{penalty.driver}</td>
                                                      <td>{penalty.session}</td>
                                                      <td>
                                                        {userRole === 'admin' ? (
                                                          <>
                                                            <button className="btn btn-success" type="button" onClick={() => {deletePenalty(penalty);}}><i className="fa fa-trash"></i></button>
                                                            <button className="btn btn-success" type="button" onClick={() => {setEditPenalty(p);}}><i className="fa fa-pencil"></i></button>
                                                          </>
                                                        ):null}
                                                        {showPenaltyAttachments === p?(
                                                          <button className="btn btn-success" type="button" onClick={() => {setShowPenaltyAttachments(null);}}><i className="fa fa-close"></i> ({penalty.attachments && penalty.attachments.length})</button>
                                                        ):(
                                                          <button className="btn btn-success" type="button" onClick={() => {setShowPenaltyAttachments(p);}}><i className="fa fa-paperclip"></i> ({penalty.attachments && penalty.attachments.length})</button>
                                                        )}
                                                      </td>
                                                  </tr>
                                                    {showPenaltyAttachments === p && penalty.attachments.map((attachment, a) => { //penalty.attachments
                                                      return <tr key={'sheet-' + i + '-row-' + r + '-penalty-' + p + '-attachment-'  + a}>
                                                        <td><i className="fa fa-paperclip"></i> {attachment.title}</td>
                                                        <td colSpan="3"><a href={attachment.attachment} target="_blank">{attachment.attachment}</a></td>
                                                        {userRole === 'admin' ? (
                                                          <td>
                                                            <button className="btn btn-success" type="button" onClick={() => {deletePenaltyAttachment(attachment);}}><i className="fa fa-trash"></i></button>
                                                          </td>
                                                        ):<td></td>}
                                                      </tr>
                                                    })}
                                                    {userRole === 'admin' && showPenaltyAttachments === p?(
                                                      <Formik
                                                        initialValues={{
                                                          title: '',
                                                          url: ''
                                                        }}
                                                        validate={values => {
                                                          const errors = {};
                                                          if (!values.title) {
                                                            errors.title = 'Required';
                                                          }
                                                          if (!values.url) {
                                                            errors.url = 'Required';
                                                          }
                                                          return errors;
                                                        }}
                                                        onSubmit={(values, { setSubmitting }) => {
                                                          insertPenaltyAttachment(penalty, values)
                                                          setSubmitting(false);
                                                        }}
                                                      >
                                                        {({
                                                          values,
                                                          handleChange,
                                                          handleBlur,
                                                          handleSubmit,
                                                          isSubmitting
                                                        }) => (
                                                        <tr key={'sheet-' + i + '-row-' + r + '-penalty-' + p + '-attachment-edit'}>
                                                          <td colSpan="5">
                                                            <form className="row" onSubmit={handleSubmit}>
                                                              <div className="col-5 item form-group">
                                                                <input type="text" className="form-control" placeholder="Title" required="" name="title"
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  value={values.title}
                                                                />
                                                                <ErrorMessage name="title" component="div" />
                                                              </div>
                                                              <div className="col-5 item form-group">
                                                                <input type="text" className="form-control" placeholder="URL" required="" name="url"
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  value={values.url}
                                                                />
                                                                <ErrorMessage name="url" component="div" />
                                                              </div>
                                                              <div className="col-2">
                                                                <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                                                  <i className="fa fa-check"></i>
                                                                </button>
                                                              </div>
                                                            </form>
                                                          </td>
                                                        </tr>
                                                        )}
                                                      </Formik>
                                                    ):null}
                                                  </>
                                                ))
                                              })}
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="modal-footer">
                                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => {setShowPenalty('');}}>Close</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ): null}
                                  </>
                                ): 'n/a'}
                                </td>
                                <td>
                                {row.nfa.length > 0?(
                                  <>
                                  <button className="btn btn-success" type="button" onClick={() => {setShowNFA('sheet-' + i + '-row-' + r);}}>View ({row.nfa.length})</button>
                                  {(showNFA === 'sheet-' + i + '-row-' + r)?(
                                    <div className="modal fade show" role="dialog" aria-modal="true" style={{display: 'block', paddingLeft: '0px'}}>
                                      <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h4 className="modal-title" id="myModalLabel">{row.article}</h4>
                                            <button type="button" className="close" data-dismiss="modal" onClick={() => {setShowNFA('');}}><span aria-hidden="true">×</span></button>
                                          </div>
                                          <div className="modal-body">
                                            <p><b>Infringement</b>: {row.infringement}</p>
                                            <p><b>Year</b>: {row.year}</p>
                                            <table className="table table-striped jambo_table bulk_action">
                                              <thead>
                                                <tr className="headings">
                                                  <th className="column-title">Penalty </th>
                                                  <th className="column-title">GP </th>
                                                  <th className="column-title">Driver </th>
                                                  <th className="column-title">Session </th>
                                                  <th className="column-title">Action </th>
                                                </tr>
                                              </thead>

                                              <tbody>
                                              {row.nfa.map((nfaItem, p) => {
                                                return ((editNFA === p && userRole === 'admin')?(
                                                  <Formik
                                                    initialValues={nfaItem}
                                                    validate={values => {
                                                      const errors = {};
                                                      if (!values.penalty) {
                                                        errors.penalty = 'Required';
                                                      }
                                                      if (!values.driver) {
                                                        errors.driver = 'Required';
                                                      }
                                                      if (!values.gp) {
                                                        errors.gp = 'Required';
                                                      }
                                                      if (!values.session) {
                                                        errors.session = 'Required';
                                                      }
                                                      return errors;
                                                    }}
                                                    onSubmit={(values, { setSubmitting }) => {
                                                      updateNFA(row, values)
                                                      setSubmitting(false);
                                                    }}
                                                  >
                                                    {({
                                                      values,
                                                      handleChange,
                                                      handleBlur,
                                                      handleSubmit,
                                                      isSubmitting
                                                    }) => (
                                                    <tr key={'sheet-' + i + '-row-' + r + '-penalty1-' + p}>
                                                      <td colSpan="5">
                                                        <form className="row" onSubmit={handleSubmit}>
                                                          <div className="col-4 item form-group">
                                                            <input type="text" className="form-control" placeholder="Penalty" required="" name="penalty"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.penalty}
                                                            />
                                                            <ErrorMessage name="penalty" component="div" />
                                                          </div>
                                                          <div className="col-2 item form-group">
                                                            <input type="text" className="form-control" placeholder="GP" required="" name="gp"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.gp}
                                                            />
                                                            <ErrorMessage name="gp" component="div" />
                                                          </div>
                                                          <div className="col-2 item form-group">
                                                            <input type="text" className="form-control" placeholder="Driver" required="" name="driver"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.driver}
                                                            />
                                                            <ErrorMessage name="driver" component="div" />
                                                          </div>
                                                          <div className="col-2 item form-group">
                                                            <input type="text" className="form-control" placeholder="Session" required="" name="session"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.session}
                                                            />
                                                            <ErrorMessage name="session" component="div" />
                                                          </div>
                                                          <div className="col-2">
                                                          <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                                              <i className="fa fa-check"></i>
                                                            </button>
                                                            <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => {setEditNFA(null);}}>
                                                              <i className="fa fa-close"></i>
                                                            </button>
                                                          </div>
                                                        </form>
                                                      </td>
                                                    </tr>
                                                    )}
                                                  </Formik>
                                                ):(
                                                  <>
                                                    <tr key={'sheet-' + i + '-row-' + r + '-nfa-' + p}>
                                                      <td>{nfaItem.penalty}</td>
                                                      <td>{nfaItem.gp}</td>
                                                      <td>{nfaItem.driver}</td>
                                                      <td>{nfaItem.session}</td>
                                                      <td>
                                                        {userRole === 'admin' ? (
                                                          <>
                                                            <button className="btn btn-success" type="button" onClick={() => {deleteNFA(nfaItem);}}><i className="fa fa-trash"></i></button>
                                                            <button className="btn btn-success" type="button" onClick={() => {setEditNFA(p);}}><i className="fa fa-pencil"></i></button>
                                                          </>
                                                        ):null}
                                                        {showNFAAttachments === p?(
                                                          <button className="btn btn-success" type="button" onClick={() => {setShowNFAAttachments(null);}}><i className="fa fa-close"></i> ({nfaItem.attachments && nfaItem.attachments.length})</button>
                                                        ):(
                                                          <button className="btn btn-success" type="button" onClick={() => {setShowNFAAttachments(p);}}><i className="fa fa-paperclip"></i> ({nfaItem.attachments && nfaItem.attachments.length})</button>
                                                        )}
                                                      </td>
                                                    </tr>
                                                    {showNFAAttachments === p && nfaItem.attachments.map((attachment, a) => {
                                                      return <tr key={'sheet-' + i + '-row-' + r + '-nfa-' + p + '-attachment-'  + a}>
                                                        <td><i className="fa fa-paperclip"></i> {attachment.title}</td>
                                                        <td colSpan="5"><a href={attachment.attachment} target="_blank">{attachment.attachment}</a></td>
                                                        {userRole === 'admin' ? (
                                                          <td>
                                                            <button className="btn btn-success" type="button" onClick={() => {deleteNFAAttachment(attachment);}}><i className="fa fa-trash"></i></button>
                                                          </td>
                                                        ):<td></td>}
                                                      </tr>
                                                    })}
                                                    {userRole === 'admin' && showNFAAttachments === p?(
                                                      <Formik
                                                        initialValues={{
                                                          title: '',
                                                          url: ''
                                                        }}
                                                        validate={values => {
                                                          const errors = {};
                                                          if (!values.title) {
                                                            errors.title = 'Required';
                                                          }
                                                          if (!values.url) {
                                                            errors.url = 'Required';
                                                          }
                                                          return errors;
                                                        }}
                                                        onSubmit={(values, { setSubmitting }) => {
                                                          insertNFAAttachment(nfaItem, values)
                                                          setSubmitting(false);
                                                        }}
                                                      >
                                                        {({
                                                          values,
                                                          handleChange,
                                                          handleBlur,
                                                          handleSubmit,
                                                          isSubmitting
                                                        }) => (
                                                        <tr key={'sheet-' + i + '-row-' + r + '-penalty-' + p + '-attachment-edit2'}>
                                                          <td colSpan="5">
                                                            <form className="row" onSubmit={handleSubmit}>
                                                              <div className="col-5 item form-group">
                                                                <input type="text" className="form-control" placeholder="Title" required="" name="title"
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  value={values.title}
                                                                />
                                                                <ErrorMessage name="title" component="div" />
                                                              </div>
                                                              <div className="col-5 item form-group">
                                                                <input type="text" className="form-control" placeholder="URL" required="" name="url"
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  value={values.url}
                                                                />
                                                                <ErrorMessage name="url" component="div" />
                                                              </div>
                                                              <div className="col-2">
                                                                <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                                                  <i className="fa fa-check"></i>
                                                                </button>
                                                              </div>
                                                            </form>
                                                          </td>
                                                        </tr>
                                                        )}
                                                      </Formik>
                                                    ):null}
                                                  </>
                                                ))
                                              })}
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="modal-footer">
                                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => {setShowNFA('');}}>Close</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ): null}
                                  </>
                                ): 'n/a'}
                                </td>
                                <td>
                                {row.attachments && row.attachments.length > 0?(
                                  <>
                                  <button className="btn btn-success" type="button" onClick={() => {setShowAttachments('sheet-' + i + '-row-' + r);}}>View ({row.attachments.length})</button>
                                  {(showAttachments === 'sheet-' + i + '-row-' + r)?(
                                    <div className="modal fade show" role="dialog" aria-modal="true" style={{display: 'block', paddingLeft: '0px'}}>
                                      <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h4 className="modal-title" id="myModalLabel">{row.article}</h4>
                                            <button type="button" className="close" data-dismiss="modal" onClick={() => {setShowAttachments('');}}><span aria-hidden="true">×</span></button>
                                          </div>
                                          <div className="modal-body">
                                            <p><b>Infringement</b>: {row.infringement}</p>
                                            <p><b>Year</b>: {row.year}</p>
                                            <table className="table table-striped jambo_table bulk_action">
                                              <thead>
                                                <tr className="headings">
                                                  <th className="column-title">Title </th>
                                                  <th className="column-title">Attachment </th>
                                                  {userRole === 'admin' ? (
                                                    <th className="column-title">Action </th>
                                                  ):null}
                                                </tr>
                                              </thead>

                                              <tbody>
                                              {row.attachments.map((attachment, p) => {
                                                return (
                                                  <tr key={'sheet-' + i + '-row-' + r + '-attachment-' + p}>
                                                    <td>{attachment.title}</td>
                                                    <td><a href={attachment.attachment} target="_blank">{attachment.attachment}</a></td>
                                                    {userRole === 'admin' ? (
                                                      <td>
                                                        <button className="btn btn-success" type="button" onClick={() => {deleteAttachment(attachment);}}><i className="fa fa-trash"></i></button>
                                                      </td>
                                                    ):null}
                                                  </tr>
                                                )
                                              })}
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="modal-footer">
                                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => {setShowAttachments('');}}>Close</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ): null}
                                  </>
                                ): 'n/a'}
                                </td>
                                {userRole === 'admin' ? (
                                  <td>
                                    <button className="btn btn-success" type="button" onClick={() => {setAddAttachments(row);}}><i className="fa fa-plus"></i> Attachments</button>
                                    <button className="btn btn-success" type="button" onClick={() => {setAddPenalty(row);}}><i className="fa fa-plus"></i> Penalty</button>
                                    <button className="btn btn-success" type="button" onClick={() => {setAddNFA(row);}}><i className="fa fa-plus"></i> NFA</button>
                                    <button className="btn btn-success" type="button" onClick={() => {setEditRow(row);}}><i className="fa fa-pencil"></i> Edit article</button>
                                    <button className="btn btn-success" type="button" onClick={() => {deleteRow(sheet._id, row._id);}}><i className="fa fa-trash"></i> Delete article</button>
                                  </td>
                                ): null}
                              </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                      {userRole === 'admin' ? (
                        <Formik
                          initialValues={{
                            article: '',
                            infringement: '',
                            year: ''
                          }}
                          validate={values => {
                            const errors = {};
                            if (!values.article) {
                              errors.article = 'Required';
                            }
                            if (!values.infringement) {
                              errors.infringement = 'Required';
                            }
                            if (!values.year) {
                              errors.year = 'Required';
                            }
                            return errors;
                          }}
                          onSubmit={(values, { setSubmitting }) => {
                            insertArticleRow(sheet._id, values)
                            setSubmitting(false);
                          }}
                        >
                          {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                          }) => (
                          <form onSubmit={handleSubmit}>
                            <h2> Add a new row into {sheet.article} </h2>
                            <div className="item form-group">
                              <input type="text" className="form-control" placeholder="Article" required="" name="article"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.article}
                              />
                              <ErrorMessage name="article" component="div" />
                            </div>
                            <div className="item form-group">
                              <input type="text" className="form-control" placeholder="Infringement" required="" name="infringement"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.infringement}
                              />
                              <ErrorMessage name="infringement" component="div" />
                            </div>
                            <div className="item form-group">
                              <input type="text" className="form-control" placeholder="Year" required="" name="year"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.year}
                              />
                              <ErrorMessage name="year" component="div" />
                            </div>
                            <div>
                              <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                <i className="fa fa-plus"></i> Add
                              </button>
                            </div>
                          </form>
                          )}
                        </Formik>
                      ):null}
                    </div>
                  </div>
                </div>
              </div>
              )})}
              {(addPenalty !== null)?(
                <div className="modal fade show" role="dialog" aria-modal="true" style={{display: 'block', paddingLeft: '0px'}}>
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">Add Penalty on {addPenalty.article}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={() => {setAddPenalty(null);}}><span aria-hidden="true">×</span></button>
                      </div>
                      <Formik
                        initialValues={{
                          penalty: '',
                          gp: '',
                          driver: '',
                          session: ''
                        }}
                        validate={values => {
                          const errors = {};
                          if (!values.penalty) {
                            errors.penalty = 'Required';
                          }
                          if (!values.driver) {
                            errors.driver = 'Required';
                          }
                          if (!values.gp) {
                            errors.gp = 'Required';
                          }
                          if (!values.session) {
                            errors.session = 'Required';
                          }
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          insertPenalty(addPenalty, values)
                          setSubmitting(false);
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                              <p><b>Infringement</b>: {addPenalty.infringement}</p>
                              <p><b>Year</b>: {addPenalty.year}</p>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Penalty" required="" name="penalty"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.penalty}
                                />
                                <ErrorMessage name="penalty" component="div" />
                              </div>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="GP" required="" name="gp"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.gp}
                                />
                                <ErrorMessage name="gp" component="div" />
                              </div>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Driver" required="" name="driver"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.driver}
                                />
                                <ErrorMessage name="driver" component="div" />
                              </div>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Session" required="" name="session"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.session}
                                />
                                <ErrorMessage name="session" component="div" />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => {setAddPenalty(null);}}>Close</button>
                              <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                <i className="fa fa-plus"></i> Add
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              ): null}
              {(addNFA !== null)?(
                <div className="modal fade show" role="dialog" aria-modal="true" style={{display: 'block', paddingLeft: '0px'}}>
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">Add NFA on {addNFA.article}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={() => {setAddNFA(null);}}><span aria-hidden="true">×</span></button>
                      </div>
                      <Formik
                        initialValues={{
                          penalty: '',
                          gp: '',
                          driver: '',
                          session: ''
                        }}
                        validate={values => {
                          const errors = {};
                          if (!values.penalty) {
                            errors.penalty = 'Required';
                          }
                          if (!values.driver) {
                            errors.driver = 'Required';
                          }
                          if (!values.gp) {
                            errors.gp = 'Required';
                          }
                          if (!values.session) {
                            errors.session = 'Required';
                          }
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          insertNFA(addNFA, values)
                          setSubmitting(false);
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                              <p><b>Infringement</b>: {addNFA.infringement}</p>
                              <p><b>Year</b>: {addNFA.year}</p>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Penalty" required="" name="penalty"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.penalty}
                                />
                                <ErrorMessage name="penalty" component="div" />
                              </div>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="GP" required="" name="gp"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.gp}
                                />
                                <ErrorMessage name="gp" component="div" />
                              </div>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Driver" required="" name="driver"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.driver}
                                />
                                <ErrorMessage name="driver" component="div" />
                              </div>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Session" required="" name="session"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.session}
                                />
                                <ErrorMessage name="session" component="div" />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => {setAddNFA(null);}}>Close</button>
                              <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                <i className="fa fa-plus"></i> Add
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              ): null}
              {(addAttachments !== null)?(
                <div className="modal fade show" role="dialog" aria-modal="true" style={{display: 'block', paddingLeft: '0px'}}>
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">Add Attachment on {addAttachments.article}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={() => {setAddAttachments(null);}}><span aria-hidden="true">×</span></button>
                      </div>
                      <Formik
                        initialValues={{
                          title: '',
                          attachment: ''
                        }}
                        validate={values => {
                          const errors = {};
                          if (!values.title) {
                            errors.title = 'Required';
                          }
                          if (!values.attachment) {
                            errors.attachment = 'Required';
                          }
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          insertAttachment(addAttachments, values)
                          setSubmitting(false);
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                              <p><b>Infringement</b>: {addAttachments.infringement}</p>
                              <p><b>Year</b>: {addAttachments.year}</p>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Title" required="" name="title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.title}
                                />
                                <ErrorMessage name="title" component="div" />
                              </div>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Attachment URL" required="" name="attachment"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.attachment}
                                />
                                <ErrorMessage name="attachment" component="div" />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => {setAddAttachments(null);}}>Close</button>
                              <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                <i className="fa fa-plus"></i> Add
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              ): null}
              {(editRow !== null)?(
                <div className="modal fade show" role="dialog" aria-modal="true" style={{display: 'block', paddingLeft: '0px'}}>
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">Edit article</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={() => {setEditRow(null);}}><span aria-hidden="true">×</span></button>
                      </div>
                      <Formik
                        initialValues={{
                          article: editRow.article,
                          infringement: editRow.infringement,
                          year: editRow.year
                        }}
                        validate={values => {
                          const errors = {};
                          if (!values.article) {
                            errors.article = 'Required';
                          }
                          if (!values.infringement) {
                            errors.infringement = 'Required';
                          }
                          if (!values.year) {
                            errors.year = 'Required';
                          }
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          editRowDo(editRow, values)
                          setSubmitting(false);
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Article" required="" name="article"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.article}
                                />
                                <ErrorMessage name="article" component="div" />
                              </div>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Infringement" required="" name="infringement"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.infringement}
                                />
                                <ErrorMessage name="infringement" component="div" />
                              </div>
                              <div className="item form-group">
                                <input type="text" className="form-control" placeholder="Year" required="" name="year"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.year}
                                />
                                <ErrorMessage name="year" component="div" />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => {setEditRow(null);}}>Close</button>
                              <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                <i className="fa fa-pencil"></i> Edit
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              ): null}
            </div>
          </div>
      </div>
    </div>
  );
}

export default Results;
