import React, { useState } from 'react';
import './Login.css';

import { Formik, ErrorMessage } from 'formik';

import { useHistory } from "react-router-dom";

import config from './config'

import SecureData from './secure'

function App() {
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const userToken = localStorage.getItem('ut');
  let lastUpdate = localStorage.getItem('last_update');
  
  if(lastUpdate !== null) {
    let tmpdate = new Date(lastUpdate);
    lastUpdate = tmpdate.getDate() + '/' + (tmpdate.getMonth() + 1) + '/' + tmpdate.getFullYear() + ' ' + tmpdate.getHours() + ':' + tmpdate.getMinutes()
  }

  if(userToken !== null) {
    history.push('/home')
  }

  const doLogin = function(params) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }

    fetch(config.API_PATH + '/login', requestOptions).catch(function(error) {
      //offline login
      try {
        const lastUser = localStorage.getItem('lu');
        if(lastUser === null || lastUser !== params.username) {
          setErrorMessage("You are offline, this mode let the last user logged in (" + lastUser + ") to use the offline login only");
          return;
        }
        const ck = JSON.parse(SecureData.decrypt(localStorage.getItem('ck'), params.password))
        if(params.username === ck.username && ck.unlockKey) {
          localStorage.setItem('uk', ck.unlockKey);
          history.push('/home')
        }
      } catch(e) {
        setErrorMessage("Invalid username or password");
      }
      return;
    })
    .then(response => {
      try {
        return response.json()
      } catch(e) {
        setErrorMessage("Unable to connect to the server.");
      }
    })
    .then(data => {
      if(data && data.status === "success") {
        localStorage.setItem('ut', data.token);
        localStorage.setItem('lu', params.username);
        let unlockKey = SecureData.hash(Math.random()*10, params.password)
        localStorage.setItem('ck', SecureData.encrypt(JSON.stringify({
          username: params.username,
          unlockKey
        }), params.password))
        localStorage.setItem('uk', unlockKey);
        localStorage.setItem('ur', data.role);
        localStorage.setItem('last_update', new Date());
        history.push('/home')
      } else {
        setErrorMessage("Unable to connect to the server.");
      }
    })
  }

  return (
    <div className="login">
      <div>
        <div className="login_wrapper">
          <div className="animate form login_form">
            <section className="login_content">
              <img src="logo.png" width="100"/>
              <Formik
                initialValues={{
                  username: '',
                  password: ''
                }}
                validate={values => {
                  const errors = {};
                  if (!values.username) {
                    errors.username = 'Required';
                  }
                  if (!values.password) {
                    errors.password = 'Required';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  doLogin(values)
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                <form onSubmit={handleSubmit}>
                  <h1> F1 Penalties Assistant </h1>
                  <div>
                    <input type="text" className="form-control" placeholder="Username" required="" name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    <ErrorMessage name="username" component="div" />
                  </div>
                  <div>
                    <input type="password" className="form-control" placeholder="Password" required="" name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <ErrorMessage name="password" component="div" />
                  </div>
                  <div>
                    {errorMessage && (<p>{errorMessage}</p>)}
                    <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                      Log in
                    </button>
                  </div>

                  <div className="clearfix"></div>

                  <div className="separator">
                    <div className="clearfix"></div>
                    <br />
                    <div>
                      <p>Last synchronization: {lastUpdate}</p>
                      <p>v2.0 - ©2020 All Rights Reserved. <a href="https://www.penguinpass.it" target="_blank">Penguinpass</a> ©</p>
                    </div>
                  </div>
                </form>
                )}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
