const CryptoJS = require("crypto-js");

const SecureData = {
  hash: function hash(key, SECRET_KEY) {
      key = CryptoJS.SHA256(key, SECRET_KEY);

      return key.toString();
  },
  encrypt: function encrypt(data, SECRET_KEY) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);

      data = data.toString();

      return data;
  },
  decrypt: function decrypt(data, SECRET_KEY) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);

      data = data.toString(CryptoJS.enc.Utf8);

      return data;
  }
}

export default SecureData