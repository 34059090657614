import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import Menu from './Menu'

import { Formik } from 'formik';

import {
  useHistory,
} from "react-router-dom";

import './Home.css';

import config from './config'

import SecureData from './secure'

function Home() {
  const history = useHistory();

  const [selectLists, setSelectLists] = useState(JSON.parse(localStorage.getItem('selectLists')));

  const userToken = localStorage.getItem('ut');
  const unlockKey = localStorage.getItem('uk');

  const getData = function() {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }

    fetch(config.API_PATH + '/articles', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        localStorage.setItem('data', SecureData.encrypt(JSON.stringify(data.data), unlockKey));
      } else {
        localStorage.removeItem('ut');
        history.push('/login')
      }
    })
  }

  const getSelectLists = function() {
    const requestOptions = {
      method: 'GET'
    }

    fetch(config.API_PATH + '/selectLists', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        localStorage.setItem('selectLists', JSON.stringify(data.data));
        setSelectLists(data.data);
      } else {
        localStorage.removeItem('ut');
        history.push('/login')
      }
    })
  }

  useEffect(() => {
    getData();
    getSelectLists();
  }, [])

  const customHandleChange = function(values, field, callback) {
    callback(field)(values.value)
  }

  if(!selectLists) return null;
  
  return (
    <div className="container body">
      <div className="main_container">
        <Menu currentPage="Home"></Menu>
        <div className="right_col" role="main">
          <div className="">
            <div className="page-title">
              <div className="title_left">
                <h3>Penalities Search</h3>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="row">
              <div className="col-md-12 col-sm-12 ">
                <div className="x_panel">
                  <div className="x_content">
                    <br />
                    <Formik
                      initialValues={{
                        article: '',
                        decision: '',
                        driver: '',
                        race: '',
                        session: '',
                        keyword: ''
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        history.push('/results', values)
                        setSubmitting(false);
                      }}
                    >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    }) => (
                      <form onSubmit={handleSubmit} className="form-horizontal form-label-left">
                        <div className="item form-group">
                          <label className="col-form-label col-md-3 col-sm-3 label-align">Article</label>
                          <div className="col-md-6 col-sm-6 ">
                            <Select
                              options={selectLists.articles}
                              name="article"
                              onBlur={handleBlur}
                              onChange={values => {customHandleChange(values, "article", handleChange)}}
                              value={{value: values.article, label: values.article}}
                            />
                          </div>
                        </div>
                        <div className="item form-group">
                          <label className="col-form-label col-md-3 col-sm-3 label-align">Decision</label>
                          <div className="col-md-6 col-sm-6 ">
                            <input type="text" className="form-control" name="decision" onChange={handleChange} onBlur={handleBlur} value={values.decision} />
                          </div>
                        </div>
                        <div className="item form-group">
                          <label className="col-form-label col-md-3 col-sm-3 label-align">Driver</label>
                          <div className="col-md-6 col-sm-6 ">
                            <Select
                              options={selectLists.drivers}
                              name="driver"
                              onBlur={handleBlur}
                              onChange={values => {customHandleChange(values, "driver", handleChange)}}
                              value={{value: values.driver, label: values.driver}}
                            />
                          </div>
                        </div>
                        <div className="item form-group">
                          <label className="col-form-label col-md-3 col-sm-3 label-align">Race</label>
                          <div className="col-md-6 col-sm-6 ">
                            <Select
                              options={selectLists.races}
                              name="race"
                              onBlur={handleBlur}
                              onChange={values => {customHandleChange(values, "race", handleChange)}}
                              value={{value: values.race, label: values.race}}
                            />
                          </div>
                        </div>
                        <div className="item form-group">
                          <label className="col-form-label col-md-3 col-sm-3 label-align">Session
                          </label>
                          <div className="col-md-6 col-sm-6 ">
                            <Select
                              options={selectLists.sessions}
                              name="session"
                              onBlur={handleBlur}
                              onChange={values => {customHandleChange(values, "session", handleChange)}}
                              value={{value: values.session, label: values.session}}
                            />
                          </div>
                        </div>
                        <div className="item form-group">
                          <label className="col-form-label col-md-3 col-sm-3 label-align">Keyword</label>
                          <div className="col-md-6 col-sm-6 ">
                            <input type="text" className="form-control" name="keyword" onChange={handleChange} onBlur={handleBlur} value={values.keyword} />
                          </div>
                        </div>
                        <div className="ln_solid"></div>
                        <div className="item form-group">
                          <div className="col-md-6 col-sm-6 offset-md-3">
                            <button type="submit" className="btn btn-success btn-large">Search</button>
                          </div>
                        </div>
                      </form>
                    )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div className="pull-right">
            Penguinpass © 2020
          </div>
          <div className="clearfix"></div>
        </footer>
      </div>
    </div>
  );
}

export default Home;
