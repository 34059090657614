import React, { useEffect, useState } from 'react';
import Menu from './Menu'
import { Formik, ErrorMessage } from 'formik';

import {
  useHistory,
} from "react-router-dom";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import config from './config'

function Results() {
  const [results, setResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  const userToken = localStorage.getItem('ut');

  const getData = function() {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }

    fetch(config.API_PATH + '/users', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        setResults(data.data)
      } else {
        localStorage.removeItem('ut');
        history.push('/login')
      }
    })
  }

  useEffect(() => {
    getData();
  }, [])

  const deleteUser = function(userId) {
    confirmAlert({
      title: 'Delete user',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const requestOptions = {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }
        
            fetch(config.API_PATH + '/users/' + userId, requestOptions)
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                getData();
              } else {
                localStorage.removeItem('ut');
                history.push('/login');
              }
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const insertUser = function(userData) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    }

    fetch(config.API_PATH + '/users', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        getData();
      } else {
        setErrorMessage(data.message);
      }
    })
  }
  
  return (
    <div className="container body">
      <div className="main_container">
        <Menu currentPage="Users"></Menu>

        <div className="right_col" role="main">
          <div className="">
            <div className="page-title">
              <div className="title_left">
                <h3>Users</h3>
              </div>
            </div>
            <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-12 col-sm-12  ">
                  <div className="x_panel">
                    <div className="x_title">
                      <h2>Manage users</h2>
                      <div className="clearfix"></div>
                    </div>

                    <div className="x_content">
                      <div className="table-responsive">
                        <table className="table table-striped jambo_table bulk_action">
                          <colgroup>
                            <col span="1" style={{width: '15%'}} />
                            <col span="1" style={{width: '40%'}} />
                            <col span="1" style={{width: '5%'}} />
                            <col span="1" style={{width: '20%'}} />
                            <col span="1" style={{width: '20%'}} />
                          </colgroup>
                          <thead>
                            <tr className="headings">
                              <th className="column-title">Username </th>
                              <th className="column-title">Role </th>
                              <th className="column-title">Actions </th>
                            </tr>
                          </thead>

                          <tbody>
                          {results.map((user, i) => {
                            return (
                              <tr key={'user-' + i}>
                                <td>{user.username}</td>
                                <td>{user.role}</td>
                                <td>
                                  <button className="btn btn-success" type="button" onClick={() => {deleteUser(user._id)}}><i className="fa fa-trash"></i></button>
                                </td>
                              </tr>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <Formik
                          initialValues={{
                            username: '',
                            password: '',
                            role: ''
                          }}
                          validate={values => {
                            const errors = {};
                            if (!values.username) {
                              errors.username = 'Required';
                            }
                            if (!values.password) {
                              errors.password = 'Required';
                            }
                            return errors;
                          }}
                          onSubmit={(values, { setSubmitting }) => {
                            insertUser(values)
                            setSubmitting(false);
                          }}
                        >
                          {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                          }) => (
                          <form onSubmit={handleSubmit}>
                            <h2> Add a new user </h2>
                            <div className="item form-group">
                              <input type="text" className="form-control" placeholder="Username" required="" name="username"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                              />
                              <ErrorMessage name="username" component="div" />
                            </div>
                            <div className="item form-group">
                              <input type="password" className="form-control" placeholder="Password" required="" name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                              />
                              <ErrorMessage name="password" component="div" />
                            </div>
                            <div className="item form-group">
                              <select className="form-control" required="" name="role"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.role}
                              >
                                <option value=""> - Select user type - </option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                              </select>
                              <ErrorMessage name="password" component="div" />
                            </div>
                            <div>
                              <p>{errorMessage}</p>
                              <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                <i className="fa fa-plus"></i> Add
                              </button>
                            </div>
                          </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Results;
